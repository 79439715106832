export default function LogoSync2 ({ size = 16 }) {
  return (
    <svg width={size} height={size} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 128 128'>
      <g fill-rule='evenodd'>
        <path fill='#0047AB' d='M0 0h128v128H0z' />
        <path d='M95.478 85.348a5.836 5.836 0 0 1-2.838 2.913l-46.09 23.643a1.53 1.53 0 0 1-1.792-.57 1.39 1.39 0 0 1-.22-.409 2.282 2.282 0 0 1-.087-.622V98.972c.007-.095.024-.189.052-.28.151-.551.51-1.019.995-1.3l31.43-16.12c2.019-1.152 2.749-3.764 1.632-5.84a4.192 4.192 0 0 0-1.59-1.65L39.361 54.485C37.314 53.523 36 51.423 36 49.112c0-2.312 1.314-4.411 3.362-5.374l46.09-23.643a1.529 1.529 0 0 1 1.79.57c.097.123.171.263.22.413.058.203.088.413.088.624v11.326c-.007.095-.024.189-.052.28a2.161 2.161 0 0 1-.996 1.3L55.075 50.727c-2.024 1.152-2.755 3.77-1.634 5.847.37.69.921 1.262 1.59 1.65l37.609 19.29c2.889 1.362 4.159 4.867 2.838 7.835z' fill='#FFF' />
      </g>
    </svg>
  )
}
