import { useState } from 'react'
import { Col, Row, Button, Typography, Alert, Divider } from 'antd'
import LogoSync2 from './assets/LogoSync2'
import Logo from './assets/Logo'
import { CheckOutlined as IconEnable } from '@ant-design/icons'
import openLiteWallet from './openLiteWallet'

const { Text, Paragraph, Link } = Typography

function App () {
  const [configClicked, setConfigClicked] = useState()
  const [showSyncLiteInfo, setShowSyncLiteInfo] = useState()
  const urlParams = new URL(document.location.href)
  const sponsorUrl = urlParams.searchParams.get('url')
  const feeDelegationConfig = { selfSignOnFailure: true, defaultDelegator: { url: sponsorUrl } }
  const configUrl = `fee-delegation-setting?config=${JSON.stringify(feeDelegationConfig)}`
  const handleConfigClicked = () => {
    setConfigClicked(true)
    setTimeout(() => setShowSyncLiteInfo(true), 2500)
  }
  const resetConfigClicked = () => {
    setConfigClicked(false)
    setShowSyncLiteInfo(false)
  }

  const handleOpenLiteWallet = () => {
    openLiteWallet(configUrl)
  }

  return (
    <Row gutter={[16, 48]}>
      <Col span={24} />

      <Col span={24}>
        <Divider plain>Enable Fee Delegation</Divider>
        <Row>
          <Col xs={{ span: 22, offset: 1 }} xl={{ span: 14, offset: 5 }} align='center'><Logo size={128} /></Col>
          <Col xs={{ span: 22, offset: 1 }} xl={{ span: 14, offset: 5 }} align='center'>
            <Paragraph>
              With Fee Delegation all dApps are available with no gas fees. All fees are paid by a Sponsorship.<br />
              You do not need to care about anything crypto, just use every dApp like a regular website.<br />
            </Paragraph>

            {!sponsorUrl && (
              <Link href='https://vechain.energy' target='_blank' rel='noopener noreferrer'>
                <Row gutter={[16, 32]}>
                  <Col span={24}><Text mark>No Sponsorship given, do you have the correct link?</Text></Col>
                  <Col span={24}><Button block type='primary' size='large' shape='round'>goto vechain.energy and create one yourself</Button></Col>
                </Row>
              </Link>
            )}

            {!!sponsorUrl && (
              <Link href={`connex:${configUrl}`} onClick={handleConfigClicked}>
                <Row gutter={[16, 16]}>
                  <Col span={24}><Button block type='primary' size='large' shape='round' icon={<IconEnable />} disabled={configClicked || configClicked}>enable fee delegation now</Button></Col>
                  <Col span={24}><Text italic style={{ fontSize: '0.75rem' }}>Sponsorship-URL: <Text code>{sponsorUrl}</Text></Text></Col>
                </Row>
              </Link>
            )}

          </Col>
        </Row>
      </Col>

      {showSyncLiteInfo && (
        <Col xs={{ span: 22, offset: 1 }} xl={{ span: 12, offset: 6 }}>
          <Alert
            type='info'
            message='Sync2 did not open?'
            description={<>Ensure Sync2 is installed. Allow your Browser to open it. If you are using Sync2-lite as web-application <Link href='#' onClick={handleOpenLiteWallet}>configure Sync2-lite</Link></>}
            onClose={resetConfigClicked}
            closable
          />
        </Col>
      )}

      <Col span={24}>
        <Divider plain>Official Sync2 Wallet required</Divider>
        <Row>
          <Col xs={{ span: 22, offset: 1 }} xl={{ span: 14, offset: 5 }} align='center'>
            <Link href='https://sync.vecha.in/' target='_blank' rel='noopener noreferrer'>
              <Row gutter={[16, 0]}>
                <Col span={24}><LogoSync2 size={32} /></Col>
                <Col span={24}><Button type='link' size='small'>Download Sync2</Button></Col>
              </Row>
            </Link>
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <Divider plain>How it works</Divider>
        <Row>
          <Col xs={{ span: 22, offset: 1 }} xl={{ span: 14, offset: 5 }} align='center'>
            <Text italic style={{ fontSize: '0.75rem' }} type='secondary'>
              Enabling fee delegation will open Sync2 Wallet with a configuration preset for the given link.<br />
              The preset is shown and requires your permission to be applied, no change will be applied by clicking on the link above.<br />
              During fee delegation the unsigned transaction is shared with the delegator to receive a payer signature.<br />
              Your wallet adds payer- and wallet signature, instructing the blockchain to charge the delegator for fees.
            </Text>
          </Col>
        </Row>
      </Col>

    </Row>
  )
}

export default App
